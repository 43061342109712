<template>
	<figure id="background-esquerda">
		<img v-if="$store.getters.theme.logo_esquerda && $store.getters.theme.logo_esquerda != ''" class="img-responsive" alt="Imagem" :src="$store.getters.theme.logo_esquerda">
		<img v-else class="img-responsive" alt="Imagem" :src="imagem">
	</figure>
</template>

<script>
	import store from '@/store'
	import Imagem from "@/assets/images/vetor-login.png"
	export default {
		name: 'LoginEsquerda',
		data() {
			if(store.getters.theme.logo_esquerda && store.getters.theme.logo_esquerda != ''){
				return {
					theme:store.theme
				}
			}else{
				return{
					imagem: Imagem
				}
			}
		},
	}
</script>

<style lang="scss">
	#background-esquerda{
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
        img{
            margin-left: 0;
        }
	}
</style>